import React, { ReactElement, useEffect, useState, useContext } from "react";
import { Affix, Alert, Button, Layout, Result } from "antd";
import TopNavigation from "../Components/TopNavigation";
import styled from "styled-components";
import { Content } from "antd/es/layout/layout";
import BaseSideNavigation from "../Components/SideNavigation/BaseSideNavigation";
import SideNavigation from "../Components/SideNavigation";
import ForecastingSideNavigation from "../Components/ForecastingSideNavigation";
import { animated, useSpring } from "react-spring";
import { ErrorBoundary } from "react-error-boundary";
import { RoutePaths } from "../Components/Router/Routes";
import { ENV, WRANGLR_LIGHT_BLUE } from "../Utils/constants";
import { Context } from "State/store";
import { useLocation } from "react-router";
import { useReturnToHomeWithBreakpoint } from "Components/RedirectMobileWrapper/RedirectMobileWrapper";
import { ServiceDown } from "./ServiceDown";

const AnimatedContent = animated(Content);

const MarginContent = styled(AnimatedContent)`
    margin: 20px;
`;

const StyledWarningBanner = styled.div`
    display: flex;
    width: 100%;
    background-color: ${WRANGLR_LIGHT_BLUE};
    color: #fff;
    margin-top: 2px;
    border-radius: 2px;
    padding: 8px;
    text-align: center;
    align-content: center;
    justify-content: center;
`;

const StyledAffix = styled(Affix)`
    z-index: 1000 !important;
`;
const StyledAlert = styled(Alert)`
    width: 100%;
    text-align: center;
    font-size: 16px;
    border-radius: 0;
    background-color: rgba(255, 204, 199, 0.4) !important;
    border: 1px solid #ffccc7 !important;
    svg {
        color: red;
    }
`;

const StyledContainer = styled.div`
    position: sticky;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;
interface Props {
    children: ReactElement | ReactElement[];
    page: string;
}

const BaseDesktopPage = ({ children, page }: Props): ReactElement => {
    useReturnToHomeWithBreakpoint({});

    const [{ groupData }] = useContext(Context);
    const location = useLocation<{ prevPath?: string }>();
    const navigatingFromHome = location.state?.prevPath === "/" ? true : false;
    const [sideNav, setSideNav] = useState<ReactElement>(
        navigatingFromHome ? (
            <SideNavigation />
        ) : (
            <ForecastingSideNavigation navigatingFromHome={navigatingFromHome} />
        )
    );

    const styles = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
    });

    useEffect(() => {
        setSideNav(
            page === "home" ? (
                <SideNavigation />
            ) : (
                <ForecastingSideNavigation navigatingFromHome={navigatingFromHome} />
            )
        );
    }, [page]);

    return process.env.REACT_APP_SERVICE_DOWN ? (
        <ServiceDown />
    ) : (
        <Layout style={{ minHeight: "100vh" }}>
            <BaseSideNavigation page={page}>{sideNav}</BaseSideNavigation>
            <Layout>
                <StyledAffix>
                    <TopNavigation mode={"desktop"} page={page} />
                    {process.env.REACT_APP_OUTAGE ? (
                        <StyledContainer>
                            <StyledAlert
                                type="warning"
                                message={process.env.REACT_APP_OUTAGE}
                            ></StyledAlert>
                        </StyledContainer>
                    ) : null}
                </StyledAffix>

                {groupData?.group?.bannerAlert && (
                    <StyledWarningBanner>
                        {groupData?.group.bannerAlert}
                    </StyledWarningBanner>
                )}
                <Layout.Content>
                    <ErrorBoundary
                        fallbackRender={({ error }) => {
                            return (
                                <div>
                                    <Result
                                        status="error"
                                        title="Something went wrong"
                                        // Only show extended error details in dev environments
                                        subTitle={
                                            ENV === "dev" ? (
                                                <details
                                                    style={{
                                                        whiteSpace: "pre-wrap",
                                                    }}
                                                >
                                                    {error.message}
                                                    <br />
                                                    <br />
                                                    {error.stack}
                                                </details>
                                            ) : null
                                        }
                                        extra={
                                            <Button
                                                type="primary"
                                                key="console"
                                                onClick={() => {
                                                    // Route back to "forecasting" Home if already on a forecasting route.
                                                    if (page == "forecasting") {
                                                        window.location.href =
                                                            RoutePaths.FORECASTING_LANDING;
                                                    } else {
                                                        window.location.href =
                                                            RoutePaths.HOME;
                                                    }
                                                }}
                                            >
                                                Go Home
                                            </Button>
                                        }
                                    />
                                </div>
                            );
                        }}
                    >
                        <MarginContent style={styles}>{children}</MarginContent>
                    </ErrorBoundary>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: "center" }}>
                    Quantaco ©{new Date().getFullYear()} All rights reserved
                </Layout.Footer>
            </Layout>
        </Layout>
    );
};

export default BaseDesktopPage;
