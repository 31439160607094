import React, { Dispatch, ReactElement, useContext, useState } from "react";
import { Button, Col, Image, Menu, Row, Switch, Tag, Typography } from "antd";
import styled from "styled-components";
import { Header } from "antd/es/layout/layout";
import { NavLink as Link } from "react-router-dom";
import UserAvatarDropdown from "../UserAvatarDropdown";
import { Context, State } from "../../State/store";
import { FORECASTING_TAB_ENABLED } from "../../Utils/constants";
import { Action } from "../../State/actions";
import salesLineLogo from "salesline-logo.svg";
import { DataExportButton } from "Pages/Dashboard/Components/DataExportButton/DataExportButton";

const FlexCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FlexLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const WhiteHeader = styled(Header)`
    background: #fff;
    padding-left: 0;
    padding-right: 0;
    height: 65px;
`;

interface Props {
    mode: "desktop" | "mobile";
    page?: string;
}

function TopNavigationMobile(): ReactElement {
    const [state]: [State, Dispatch<Action>] = React.useContext(Context);
    const { groupData } = state;

    return (
        <>
            {groupData && (
                <WhiteHeader>
                    <Row
                        style={{
                            borderBottom: "1px solid #d9d9d9",
                            minHeight: "65px",
                        }}
                        justify={"center"}
                    >
                        <FlexCol flex={"200px"}>
                            <FlexLink to={"/"}>
                                <Image
                                    src={salesLineLogo}
                                    preview={false}
                                    width="200px"
                                    height={40}
                                />
                            </FlexLink>
                        </FlexCol>
                    </Row>
                </WhiteHeader>
            )}
        </>
    );
}

interface TopNavigationDesktopProps {
    page?: string;
}

function TopNavigationDesktop({ page }: TopNavigationDesktopProps): ReactElement {
    const [current, setCurrent] = useState(page!);
    const [{ groupData }] = useContext(Context);

    const handleClick = (e) => {
        setCurrent(e.key);
    };

    const hasAccessToMappingPage = Boolean(
        groupData?.group.features.mappingConfiguratorEnabled
    );

    return (
        <WhiteHeader>
            <Row style={{ borderBottom: "1px solid #d9d9d9" }}>
                <Col flex={"auto"}>
                    <Menu
                        onClick={handleClick}
                        activeKey={current}
                        selectedKeys={[current]}
                        mode="horizontal"
                    >
                        <Menu.Item key="home">
                            <Link
                                to={{
                                    pathname: "/",
                                    state: { prevPath: location.pathname },
                                }}
                            >
                                Home
                            </Link>
                        </Menu.Item>
                        {FORECASTING_TAB_ENABLED === "true" && (
                            <Menu.Item key="forecasting">
                                <Link
                                    to={{
                                        pathname: "/forecasting",
                                        state: { prevPath: location.pathname },
                                    }}
                                >
                                    <Typography.Text>Forecasting</Typography.Text>{" "}
                                </Link>
                            </Menu.Item>
                        )}
                        {process.env.REACT_APP_ENV !== "prod" && (
                            <Menu.Item key="forecast">
                                <Link
                                    to={{
                                        pathname: "/forecast",
                                    }}
                                >
                                    <Typography.Text>Forecast</Typography.Text>
                                    <Tag>Beta</Tag>
                                </Link>
                            </Menu.Item>
                        )}
                        {hasAccessToMappingPage && (
                            <Menu.Item key="mapping">
                                <Link
                                    to={{
                                        pathname: "/mapping",
                                        state: { prevPath: location.pathname },
                                    }}
                                >
                                    <Typography.Text>Mapping</Typography.Text>
                                </Link>
                            </Menu.Item>
                        )}
                    </Menu>
                </Col>
                {hasAccessToMappingPage && (
                    <>
                        <FlexCol style={{ marginRight: 22 }}>
                            <DataExportButton />
                        </FlexCol>
                    </>
                )}
                <FlexCol
                    style={{
                        marginRight: 22,
                    }}
                >
                    <Button
                        style={{
                            border: "none",
                            marginRight: 10,
                        }}
                        href="https://quantaco.notion.site/Salesline-Help-3bff29d10ea3493f885219a695520b27"
                        target="_blank"
                        rel="noreferrer"
                        icon={<img src="/open-book.svg" />}
                    />
                    <UserAvatarDropdown />
                </FlexCol>
            </Row>
        </WhiteHeader>
    );
}

export default function TopNavigation({ mode, page }: Props): ReactElement {
    if (mode === "desktop") {
        return <TopNavigationDesktop page={page!} />;
    } else {
        return <TopNavigationMobile />;
    }
}
